/* src/index.css */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-size: 2.5rem;
}

@media (max-width: 600px) {
  h1 {
    font-size: 2rem;
  }
}

p {
  font-size: 1.25rem;
}

@media (max-width: 600px) {
  p {
    font-size: 1rem;
  }
}
