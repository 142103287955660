/* General styles for all screen sizes */
.amenity-card {
  transition: transform 0.3s, box-shadow 0.3s;
}

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  background-color:rgb(21, 30, 40);
  z-index: 1000;
}

.navbar ul {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 2rem;
  margin: 0;
}

.navbar li {
  margin: 0 1rem;
  cursor: pointer;
  color: white;
  font-weight: bold;
  transition: color 0.3s ease;
}

.navbar li:hover {
  color: #ffcc00;
}

.overview-container {
  margin-top: 60px; /* To offset the height of the navbar */
}
.amenity-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 4px 20px rgba(245, 222, 179, 1);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.cover-image-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px;
   /* Default margin for larger screens */
}

.cover-image-container img {
  width: 100%;
  height: auto;
}

@media (max-width: 600px) {
  .cover-image-container {
    flex-direction: column;
   
  }

  .cover-image-container .logo-container {
    text-align: center;
    margin-bottom: 20px; /* Ensure there's space below the logo */
  }
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: 'wheat'; /* Change to your preferred color */
}

.navbar-left,
.navbar-center,
.navbar-right {
  display: flex;
  align-items: center;
}

.menu-icon {
  font-size: 24px;
  cursor: pointer;
  color: #fff; /* Change to your preferred color */
}

.logo {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: #fff; /* Change to your preferred color */
}

.contact-number {
  margin-right: 1.5%;
  font-size: 16px;
  color: #fff; /* Change to your preferred color */
}

@media screen and (max-width: 768px) {
  .navbar-center {
      flex-grow: 1;
      justify-content: center;
  }
}


.overview-container {
  position: relative;
  background-size: cover;
  background-position: center;
  animation: fadeIn 2s ease-in-out;
  background-color: rgb(21, 30, 40); /* Background for the overview section */
  min-height: 100vh;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  box-sizing: border-box;
}

.unit-type-item {
  cursor: pointer;
  transition: background-color 0.3s;
}

.unit-type-item:hover {
  background-color: #f0f0f0;
}

.footer {
  text-align: center;
  padding: 10px 0;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.footer p {
  margin: 0;
  color: #F5DEB3;
  font-size: 14px;
}

body {
  font-family: 'Beckman', sans-serif;
}
.unit-type-item.Mui-selected {
  background-color: #d3a62c;
  color: white;
}
@font-face {
  font-family: 'Beckman';
  src: url('/fonts/Beckman.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

.overview-text {
  font-family: "Beckman";
  color: white;
  text-align: left;
  animation: fadeIn 3s ease-in-out;
}

.fade-in-section {
  margin-top: 10%;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
}

.fade-in-image {
  width: 70%;
  height: auto;
  margin-bottom: 20px;
}

.fade-in-text {
  width: 100%;
  text-align: center;
  opacity: 0;
  transform: translateX(-20px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.fade-in-section.is-visible .fade-in-text {
  opacity: 1;
  transform: none;
}

.typewriter-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.typewriter-content {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  width: 100%;
}

.typewriter-image-container {
  flex: 1;
  display: flex;
  justify-content: center;
}

.typewriter-image {
  max-width: 100%;
  height: auto;
}

.typewriter-text-container {
  flex: 2;
  padding: 0 20px;
}

.typewriter-line {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  border-right: .15em solid orange;
  animation: typing 4s steps(30, end), blink-caret .75s step-end infinite;
  white-space: normal;
  line-height: 1.5;
  margin-bottom: 10px;
}

.typewriter-line:nth-child(even) {
  animation: typing 5s steps(30, end), blink-caret .75s step-end infinite;
}

.MuiTab-root {
  font-size: 1rem;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink-caret {
  from, to {
    border-color: transparent;
  }
  50% {
    border-color: orange;
  }
}

.card-hover {
  transition: box-shadow 0.3s ease; /* Smooth transition for shadow */
}

.card-hover:hover {
  box-shadow: 0 4px 8px  #d3a62c; /* Wheat-colored shadow */
}
/* Media queries for mobile view */
@media (max-width: 768px) {
  .overview-container {
    height: auto;
  }

  .overlay {
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  .overlay img {
    width: 100%;
    height: auto;
    margin-top: 10px;
  }

  .overview-text {
    text-align: left;
    font-size: 1.5rem;
  }

  .footer-text {
    text-align: left;
    font-size: 1.5rem;
  }
  .amenity-card {
    max-width: 100%;
    margin: 0 auto;
    padding: 8px;
  }

  .fade-in-image {
    width: 100%;
  }

  .unit-type-item {
    font-size: 0.8rem;
  }

  .footer {
    position: relative;
    padding: 20px 0;
  }

  .fade-in-section {
    margin-top: 5%;
  }

  .typewriter-line {
    font-size: 1rem;
  }

  .typewriter-content {
    flex-direction: column;
  }

  .typewriter-text-container {
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .overview-text {
    font-size: 1.2rem;
  }

  .amenity-card {
    max-width: 100%;
    padding: 8px;
  }

  .fade-in-section {
    margin-top: 2%;
  }

  .typewriter-line {
    font-size: 0.9rem;
  }
}

@keyframes drop {
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideInRight {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.about-us-card img {
  width: 100%;
  height: auto;
}

.about-us-text {
  text-align: center;
  margin: 0 10%;
}

@media (max-width: 600px) {
  .about-us-text {
    margin: 0 5%;
  }
}

.slide-in-right {
  animation: slideInRight 1s ease-in-out;
}

@keyframes pop {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(2);
  }
}

.pop-drop-animation {
  animation: drop 1s ease-in-out, pop 1s 1s infinite;
}
