.App {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}



.App-header {
  position: relative;
  background-size: cover;
  background-position: center;
  animation: fadeIn 2s ease-in-out;
  background-color: rgb(21, 30, 40); /* Background for the overview section */
  min-height: 100vh;

}
.overview-container {
  position: relative;
  background-size: cover;
  background-position: center;
  animation: fadeIn 2s ease-in-out;
  background-color: rgb(21, 30, 40); /* Background for the overview section */
  min-height: 100vh;
}